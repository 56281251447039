import axios from 'axios';

/**
 * Legacy function (taken out of HrbrAgreementEditor) to get agreement data.
 * Requires at least one of: agreementId, superdocId
 * @param {Object} param Params object
 * @param {string | null} agreementId The agreement (short) id
 * @param {string | null} linkId link display id
 * @param {superdocId | null} superdocId The SuperDoc id
 * @returns 
 */
export const getAgreementDetails = async ({ agreementId, linkId, superdocId = null }) => {
  const options = {
    requesttype: 'agreementeditor-getagreementdetails',
    agreementid: agreementId,
    link_display_id: linkId,
    superdoc_id: superdocId,
  };

  const url = `${getBaseUrl()}/data?agreementeditor-getagreementdetails`;
  const res = await axios.post(url, options);
  return res.data;
};

export const AgreementEditorService = {
  getAgreementDetails,
};

function getBaseUrl() {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  return baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
}