<script>
import { useBillingStore } from '@/pages/Settings/stores/billing-store';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';
import { storeToRefs } from 'pinia';
import { useHarbourStore } from '../../stores/harbour-store';
import HrbrAgreementEditor from './HrbrAgreementEditor.vue';
import HrbrAgreementEditorSaveIndicator from './HrbrAgreementEditorSaveIndicator.vue';

export default {
  name: 'HrbrAgreementEditorModal',

  props: {
    creationMode: {
      type: String,
      default: 'agreement',
      validator(value) {
        const allowedValues = ['agreement', 'template'];
        return allowedValues.includes(value);
      },
    },
    initialStepId: {
      type: String,
      default: null,
    },
    agreementId: {
      type: String,
      default: null,
    },
    linkDisplayId: {
      type: String,
      default: null,
    },
    linkRecordTitle: {
      type: String,
      default: '',
    },
    linkSigners: {
      type: Array,
      default: () => [],
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
    addonKey: {
      type: String,
      default: null,
    },
    lastbrand: {
      type: Boolean,
      default: false,
    },
    linkItem: {
      type: Object,
    },
    source: {
      type: String,
    },
    agreementLinkPrevFlow: {
      type: Boolean,
      default: false,
    },
    templateGroupId: {
      type: String,
      default: null,
    },
    isEditingApprovalFlowLink: {
      type: Boolean,
      default: false,
    },
    isCompletedApproval: {
      type: Boolean,
      default: false,
    },
    approvalEditData: {
      type: Object,
      default: () => ({}),
    },
    fileToUpload: {
      type: File,
    },

    // ckeditor props
    ckeditorFileId: {
      type: String,
      default: null,
    },
    ckeditorHtmlData: {
      type: String,
      default: null,
    },
    isSkipToCk: {
      type: Boolean,
      default: false,
    },
    isBlankCkDocument: {
      type: Boolean,
      default: false,
    },
    isCkDocumentFromFile: {
      type: Boolean,
      default: false,
    },
    documentTitle: {
      type: String,
      default: ''
    },
    onlySigner: {
      type: Boolean,
      default: false,
    },
    fileId: {
      type: String,
      default: '',
    },
    ckHtmlAnchors: {
      type: Array,
      defaul: () => [],
    },

    // drafts
    sourceDraftId: {
      type: String,
      default: null,
    },

    // pdf drafts
    isPdfDocumentFromFile: {
      type: Boolean,
      default: false,
    },

    // edit agreement
    publishLock: {
      type: String,
      default: ''
    },

    // SuperDoc
    superdoc: {
      type: Object,
      default: null,
    }
  },

  components: {
    HrbrAgreementEditor,
    HrbrAgreementEditorSaveIndicator,
  },

  setup() {
    const magicAnnotationsStore = useMagicAnnotationsStore();
    const { isOnlySigner, preloadedFileId } = storeToRefs(magicAnnotationsStore);

    return {
      harbourStore: useHarbourStore(),
      billingStore: useBillingStore(),
      isOnlySigner,
      preloadedFileId
    };
  },

  data() {
    return {
      creationModeInternal: this.creationMode,
      currentStep: this.superdoc ? 1: 0,
      agreementTitle: '',
      templateBase64: this.base64Data,
      ckeditorError: null,
      errorModalOpened: false,
      isPdfPagesReady: false,
      isRemoveFile: false,
      // ckeditor data
      waitingForCkeditorInstance: true,

      // pdf drafts
      isAgreeUpdatePollingAvailable: false,
    };
  },

  provide() {
    return {
      nextStep: this.nextStep,
      prevStep: this.prevStep,
    };
  },

  computed: {
    headerTitle() {
      if (this.currentStep === 0) return 'New agreement';
      if (this.isAgreementCreationMode) {
        const editTitle = this.headerSubtitle ? 'Edit agreement:' : 'Edit agreement';
        const isEditMode = this.isAgreementEditMode && !this.agreementLinkPrevFlow;
        return isEditMode ? editTitle : 'Create agreement';
      }
      if (this.isTemplateCreationMode) {
        const editTitle = this.headerSubtitle ? 'Edit template:' : 'Edit template';
        return this.isTemplateEditMode ? editTitle : 'Create template';
      }
      return '';
    },

    headerSubtitle() {
      if (this.isAgreementEditMode && !this.agreementLinkPrevFlow) {
        return this.linkRecordTitle;
      }
      if (this.isTemplateEditMode) {
        return this.agreementTitle;
      }
      return '';
    },

    createButtonTitle() {
      if (this.isTemplateEditMode || (this.isAgreementEditMode && !this.agreementLinkPrevFlow)) {
        return 'Update';
      }
      return 'Create';
    },

    stepOptions() {
      const lastStepLabel =
        this.isAgreementEditMode && !this.agreementLinkPrevFlow
          ? 'Update agreement'
          : 'Create agreement';

      const lastStep = {
        step: 3,
        id: 'create-link',
        label: lastStepLabel,
      };

      const options = [
        {
          step: 0,
          id: 'upload-document',
          label: 'Upload document',
        },
        {
          step: 1,
          id: 'add-signers',
          label: 'Add signers',
        },
        {
          step: 2,
          id: 'add-fields',
          label: 'Place fields',
        },
        ...(this.isAgreementCreationMode ? [lastStep] : []),
      ];
      return options;
    },

    currentStepId() {
      const step = this.stepOptions.find((item) => item.step === this.currentStep);
      const stepId = step.id;
      return stepId;
    },

    isPrevDisabled() {
      const firstStep = 0;
      return this.currentStep <= firstStep || (this.isSkipToCk && this.currentStep === 1);
    },

    isCreateButton() {
      const thirdStep = 2;
      return (
        this.currentStep === thirdStep &&
        this.creationModeInternal === 'template' &&
        !this.isSkipToCk
      );
    },

    disableNextButton() {
      if (this.superdoc) return false;

      // For PDF-based links,
      if (this.currentStep === 1 && this.isPdfPagesReady) {
        return true;
      }

      const disableConditions = [
        this.waitingForCkeditorInstance,
        this.ckeditorError,
      ];
      return this.currentStep === 2 && disableConditions.some((c) => c);
    },

    isAgreementCreationMode() {
      return this.creationModeInternal === 'agreement';
    },

    isTemplateCreationMode() {
      return this.creationModeInternal === 'template';
    },

    isAgreementEditMode() {
      return this.isAgreementCreationMode && this.agreementId;
    },

    isTemplateEditMode() {
      return this.isTemplateCreationMode && this.agreementId;
    },

    isHiddenFooter() {
      return this.currentStep == 0;
    },

    previousButton() {
      return {
        style: { width: '100px' },
        styleType: 'secondary',
        iconLeft: 'fa-regular fa-angle-left',
        text: 'Previous',
        disabled: this.isPrevDisabled,
      };
    },

    nextButton() {
      return {
        style: { width: '100px' },
        styleType: 'primary',
        iconRight: 'fa-regular fa-angle-right',
        text: 'Next',
        disabled: this.disableNextButton,
      };
    },

    createButton() {
      return {
        style: { width: '100px' },
        styleType: 'primary',
        text: this.createButtonTitle,
        disabled: this.disableNextButton,
      };
    },
  },

  watch: {
    currentStepId: {
      handler(newStepId) {
        this.toggleModalFullScreenClass(newStepId);
      },
      immediate: true,
    },
  },

  methods: {
    updateCreationMode(creationMode) {
      this.creationModeInternal = creationMode;
    },

    updateAgreementTitle(agreementTitle) {
      this.agreementTitle = agreementTitle;
    },

    updateActiveTemplateId(activeTemplateId) {
      this.$emit('update-active-agreementid', activeTemplateId);
    },

    openPricingTableModal() {
      this.billingStore.openPricingTableModal();
    },
    updateCurrentStep(step) {
      this.currentStep = step;
    },
    onNextButtonClick() {
      this.harbourStore.updateHTMLPageTitle(this.agreementTitle);
      this.$emit('next-step', {
        currentStep: this.currentStep,
        currentStepId: this.currentStepId,
      });
    },

    onPrevButtonClick() {
      this.$emit('prev-step', {
        currentStep: this.currentStep,
        currentStepId: this.currentStepId,
      });
    },

    nextStep() {
      const { stepper } = this.$refs;
      if (!stepper) return;
      stepper.next();
    },

    prevStep() {
      const { stepper } = this.$refs;
      if (!stepper) return;
      stepper.prev();
    },

    setInitialStep() {
      if (!this.initialStepId) return;
      const step = this.stepOptions.find((item) => item.id === this.initialStepId);
      if (step) this.currentStep = step.step;
    },

    removeModalOpenedClass() {
      document.documentElement.classList.remove('hrbr-agreement-editor-modal--opened');
    },

    handleBeforeUnload(event) {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to close the page?';
      return false;
    },

    closeModal() {
      this.$parent.close();
    },

    getLatestUserPreferences() {
      this.harbourStore.setLastCreatedAgreementLinkOptions();
      this.harbourStore.getUserLastSelectedOptions();
    },

    confirmCloseModal() {
      const { agreementEditorComponent = {} } = this.$refs;
      const { uploadedFile } = agreementEditorComponent;

      if (!uploadedFile) {
        this.getLatestUserPreferences();
        this.closeModal();
        return;
      }

      this.$buefy.dialog.confirm({
        title: 'Close agreement editor',
        message: 'Are you sure you want to close this Harbour window?',
        confirmText: 'Close',
        onConfirm: () => {
          this.getLatestUserPreferences();
          this.closeModal();
          this.harbourStore.resetHTMLPageTitle();
          window.parent.postMessage('close', '*');
        },
      });
    },

    openFatalErrorModal(closeModal = true) {
      if (this.errorModalOpened) return;
      this.errorModalOpened = true;
      this.$buefy.dialog.alert({
        title: 'Error',
        message: 'Document could not be processed — please contact support@harbourshare.com for assistance',
        confirmText: 'Go back',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        onConfirm: () => {
          if (closeModal) this.closeModal();
        },
      });
    },

    linkbuilderErrorCheck({ error, closeModal = false }) {
      const errorString = `HrbrAgreementEditorModal - linkbuilderError: ${error}`;
      try {
        Sentry.captureException(new Error(errorString));
      } catch (err) { /* */ }
      this.openFatalErrorModal(closeModal);
    },

    checkPdfPagesReady(value) {
      this.isPdfPagesReady = value;
    },

    /**
     * @ckeditor method
     */
    onCkeditorReady() {
      this.updateWaitingCkInstance(false);
    },

    onCkeditorDestroy() {
      this.updateWaitingCkInstance(true);
    },

    updateWaitingCkInstance(value) {
      this.waitingForCkeditorInstance = value;
    },

    onCkeditorError(error) {
      this.ckeditorError = error;
      const errorString = `HrbrAgreementEditorModal - CKEditor error: ${error}`;
      console.log(errorString);
      try {
        Sentry.captureException(new Error(errorString));
      } catch { }
      this.openFatalErrorModal();
    },

    toggleModalFullScreenClass(stepId) {
      const modalElem = document.querySelector('.hrbr-agreement-editor-modal');
      const animationElem = modalElem?.querySelector('.animation-content');
      if (!modalElem) return;

      const handlers = {
        'upload-document': () => {
          modalElem.classList.remove('is-full-screen');
          animationElem.removeAttribute('style');
          this.isRemoveFile = true;
        },
        'add-signers': () => {
          modalElem.classList.add('is-full-screen');
          animationElem.removeAttribute('style');
        },
        'add-fields': () => {
          modalElem.classList.add('is-full-screen');
          animationElem.removeAttribute('style');
        },
      };

      const handler = handlers[stepId];
      if (handler) handler();
    },

    onSaveAgreementChanges() {
      this.triggerIndicatorUpdate();
    },

    triggerIndicatorUpdate() {
      this.$refs.saveIndicator?.updateIndicatorStates();
    },

    onChangeAgreeUpdatePollingAvailable(value) {
      this.isAgreeUpdatePollingAvailable = value;
    },
  },

  async created() {
    this.setInitialStep();
    //preloading hubspot related data
    if (!this.harbourStore.workspaceCustomProperties) {
      await this.harbourStore.getPersonalizationContent();
    }

    if (window.location.hostname !== 'localhost') {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    // if edit mode and not a ckeditor agreement,
    // don't wait for ckeditor instance to load
    if (this.agreementId && !this.ckeditorFileId) {
      this.updateWaitingCkInstance(false);
    }

    // open upload modal for only signer with preloaded file
    if (this.onlySigner && this.fileId) {
      this.isOnlySigner = this.onlySigner;
      this.preloadedFileId = this.fileId;
    }
  },

  beforeDestroy() {
    this.removeModalOpenedClass();
    this.harbourStore.resetHTMLPageTitle();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
};
</script>

<template>
  <div id="hrbr-agreement-editor-modal"
    :class="['hrbr-agreement-editor-modal__card', 'modal-card', currentStep === 0 && 'upload-step']">
    <div class="hrbr-agreement-editor-modal__header modal-card-head">
      <div class="hrbr-agreement-editor-modal__header-col hrbr-agreement-editor-modal__header-col--title">
        <div class="hrbr-agreement-editor-modal__header-title">
          <div class="hrbr-agreement-editor-modal__header-title-content">
            <span>{{ headerTitle }}</span>
            {{ headerSubtitle }}
          </div>
        </div>

        <template v-if="isAgreeUpdatePollingAvailable">
          <b-tooltip
            label="Changes automatically saved"
            position="is-bottom"
            type="is-light"
            :append-to-body="true">
            <HrbrAgreementEditorSaveIndicator ref="saveIndicator" />
          </b-tooltip>
        </template>
      </div>
      <div class="hrbr-agreement-editor-modal__header-col hrbr-agreement-editor-modal__header-col--steps">
        <b-steps v-if="currentStep !== 0" class="hrbr-agreement-editor-modal__steps" v-model="currentStep"
          :has-navigation="false" type="is-success" size="is-medium" label-position="right" mobile-mode="minimalist"
          ref="stepper">
          <b-step-item header-class="hrbr-agreement-editor-modal__step-item" v-for="(step, index) in stepOptions"
            :clickable="false" :key="step.id" :step="step.step + 1" :label="step.label">
          </b-step-item>
        </b-steps>
      </div>
    </div>

    <div :class="{ 'hrbr-agreement-editor-modal__first-step': isHiddenFooter }"
      class="hrbr-agreement-editor-modal__body modal-card-body">

        <HrbrAgreementEditor
          v-bind="{
            currentStep,
            currentStepId,
            creationMode: creationModeInternal,
            agreementId,
            templateGroupId,
            linkDisplayId,
            linkSigners,
            isCopy,
            addonKey,
            ckeditorFileId,
            ckeditorHtmlData,
            documentTitle,
            lastbrand,
            isSkipToCk,
            templateBase64,
            linkItem,
            source,
            isRemoveFile,
            isBlankCkDocument,
            isEditingApprovalFlowLink,
            isCompletedApproval,
            approvalEditData,
            sourceDraftId,
            fileToUpload,
            isCkDocumentFromFile,
            isPdfDocumentFromFile,
            ckHtmlAnchors,
            publishLock,
            superdocProp: superdoc
          }"
          @open-pricing-table-modal="openPricingTableModal"
          @update-active-agreementid="updateActiveTemplateId"
          @update-creation-mode="updateCreationMode"
          @update-agreement-title="updateAgreementTitle"
          @current-step-change="updateCurrentStep"
          @close-modal="closeModal"
          @editor-error="onCkeditorError"
          @editor-ready="onCkeditorReady"
          @editor-destroy="onCkeditorDestroy"
          @error-storing-ck-agreement="linkbuilderErrorCheck"
          @is-waiting-for-pdf-pages="checkPdfPagesReady"
          @save-agreement-changes="onSaveAgreementChanges"
          @change-agree-update-polling-available="onChangeAgreeUpdatePollingAvailable"
          @update-waiting-ck-instance="updateWaitingCkInstance"
          ref="agreementEditorComponent">
      </HrbrAgreementEditor>
    </div>

    <div v-if="!isHiddenFooter" class="hrbr-agreement-editor-modal__footer modal-card-foot">
      <div class="hrbr-agreement-editor-modal__footer-buttons">
        <HrbrButton :button="previousButton" @click="onPrevButtonClick"/>
        <HrbrButton v-if="isCreateButton" :button="createButton" @click="onNextButtonClick"/>
        <HrbrButton v-else id="btn-ae-next-step" :button="nextButton" @click="onNextButtonClick"/>
      </div>
    </div>
    <button type="button" :class="{ 'first-step-close': currentStep === 0 }"
      class="hrbr-agreement-editor-modal__close modal-close is-large" @click="confirmCloseModal"></button>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-agreement-editor-modal {
  &__header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    background: #fff;
    padding: 12px 20px;
    height: var(--modal-header-height);
  }

  &__header-title {
    font-size: 18px;
    font-weight: 500;
    max-width: 350px;
    min-width: 50px;

    span {
      font-weight: 500;
    }
  }

  &__card {
    &.upload-step {
      .hrbr-agreement-editor-modal__header {
        display: block;
        height: auto;
        padding: 24px 24px 12px 24px;
        border: none;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        /* bottom line appears on tablet */
        &:after {
          display: block;
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          height: 2px;
          width: 100%;
          background-color: #fff;
          z-index: 0;
        }
      }

      .hrbr-agreement-editor-modal__header-title {
        font-size: 24px;
        color: #333333;

        span {
          font-weight: 600;
        }
      }
    }
  }

  &__first-step {
    border-radius: 0 0 8px 8px;
  }
}
</style>

<style>
html.hrbr-agreement-editor-modal--opened {
  overflow: hidden !important;
}

.hrbr-agreement-editor-modal+.modal-close {
  top: 16px;
}

.hrbr-agreement-editor-modal .modal-close::before,
.hrbr-agreement-editor-modal .modal-close::after {
  background: #333;
}

.hrbr-agreement-editor-modal .first-step-close::before,
.hrbr-agreement-editor-modal .first-step-close::after {
  background: #fff !important;
}

.hrbr-agreement-editor-modal .modal-close::before {
  width: 60%;
}

.hrbr-agreement-editor-modal .modal-close::after {
  height: 60%;
}

.hrbr-agreement-editor-modal .modal-close:hover::before,
.hrbr-agreement-editor-modal .modal-close:hover::after {
  background: #fff;
}

.hrbr-agreement-editor-modal--hidden.modal {
  display: none;
}

#hrbr-agreement-editor-modal {
  --modal-header-height: 65px;
  --modal-footer-height: 60px;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__card {
  background: #f5f5f5;
}


#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__header-col--title {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 0;
  line-height: 1.2;
  padding-right: 30px;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__header-col--steps {
  display: flex;
  justify-content: center;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__header-title-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__steps .step-items {
  column-gap: 50px;
  flex-wrap: nowrap;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__steps .step-content {
  display: none;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__step-item {
  flex-basis: auto;
  flex-grow: initial;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__step-item .step-title {
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__step-item .step-marker {
  font-weight: 600;
  transition: all 0.2s ease;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__step-item.is-previous .step-marker::before {
  display: inline-block;
  content: '\f00c';
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__step-item.is-previous .step-marker span {
  display: none;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__body {
  padding: 0;
  -webkit-overflow-scrolling: auto !important;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__body .first-step {
  border-radius: 0 0 6px 6px;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 8px 20px;
  height: var(--modal-footer-height);
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__footer-buttons {
  display: flex;
  align-items: center;
  gap: 30px;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__footer-button {
  font-family: inherit;
  font-size: 16px;
  color: #363636;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  min-height: 42px;
  min-width: 120px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  background: none;
  border: none;
  outline: none;
  appearance: none;
  transition: all 0.2s ease;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__footer-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__footer-button--prev {
  border: 1px solid #dbdbdb;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__footer-button--prev:hover {
  border-color: #b5b5b5;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__footer-button--next {
  color: #fff;
  background: var(--main-primary-color);
  border: 1px solid transparent;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-modal__footer-button--next:hover {
  background: var(--main-primary-color-activefocus);
}
</style>
