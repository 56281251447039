<script>
import { useHarbourStore } from '@/stores/harbour-store';
import handleDynamicError from '@/utils/dynamic-errors';
import { mapState } from 'pinia';
import { defineAsyncComponent } from 'vue';

export default {
  name: 'HrbrAgreementLinkModal',

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    templateId: {
      type: String,
      default: null,
    },
    linkDisplayIdToEdit: {
      type: String,
      default: null,
    },
    folderId: {
      type: String,
      default: null,
    },
    agreementCreationMode: {
      type: String,
      default: null,
    },
    isAgreementSignOrderCheckboxHidden: {
      type: Boolean,
      default: false,
    },
    isAgreementEditorFlow: {
      type: Boolean,
      default: false,
    },
    lastbrand: {
      type: Boolean,
      default: false,
    },
    sourceTemplateTitle: {
      type: String,
    },
    magicLink: {
      type: String
    },
    isEditingApprovalFlowLink: {
      type: Boolean,
      default: false,
    },
    approvalEditData: {
      type: Object,
    },
    forwardingLink: {
      type: String,
    },

    // ckeditor props
    ckeditorFileId: {
      type: String,
      default: null,
    },
    ckeditorHtmlData: {
      type: String,
      default: null,
    },
    ckeditorAnchors: {
      type: Array,
      default: () => [],
    },
    forceCkeditorHtml: {
      type: Boolean,
    },
    draftId: {
      type: String,
      default: null,
    },
    isCompletedApproval: {
      type: Boolean,
      default: false,
    },
    linkObject: {
      type: Object,
      default: null,
    },

    superdocId: {
      type: String,
      default: null,
    },
    superdocProp: {
      type: Object,
      default: null,
    },
    forceUseAgreementTitle: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    // Define the main component asynchronously so that it and its internal dependencies
    // are imported only when the modal is opened. This is needed to avoid dependency issues on all pages,
    // since internal dependencies may use global variables that are not defined on all pages.
    HrbrAgreementLink: defineAsyncComponent(
      () => import('./HrbrAgreementLink.vue').catch((err) => handleDynamicError(err))
    ),
  },

  data() {
    return {
      currentStep: 3, // never changes
      linkTitle: '',
      isLinkAvailable: false,
      isStoringLoadingLink: false,
      isPendingValueChanges: false,
      isAgreeBuilderDataLoaded: false,
      approvalData: null,
      hasMadeInputChanges: false,
    };
  },

  setup() {
    const harbourStore = useHarbourStore();

    return {
      harbourStore,
    };
  },

  computed: {
    getApprovalData() {
      return this.approvalData;
    },

    stepOptions() {
      const lastStepLabel = this.linkDisplayIdToEdit ? 'Update agreement' : 'Create agreement';
      const lastStep = {
        step: 3,
        id: 'create-link',
        label: lastStepLabel,
      };

      return [
        {
          step: 0,
          id: 'upload-document',
          label: 'Upload document',
        },
        {
          step: 1,
          id: 'add-signers',
          label: 'Add signers',
        },
        {
          step: 2,
          id: 'add-fields',
          label: 'Place fields',
        },
        lastStep,
      ];
    },

    isFooterButtonDisabled() {
      return this.isStoringLoadingLink || !this.isAgreeBuilderDataLoaded;
    },

    previousButton() {
      return {
        style: { width: '100px' },
        styleType: 'secondary',
        iconLeft: 'fa-regular fa-angle-left',
        text: 'Previous',
        disabled: this.isFooterButtonDisabled,
      };
    },

    createButton() {
      return {
        style: { width: '100px' },
        styleType: 'primary',
        iconRight: 'fa-regular fa-angle-right',
        text: 'Create',
        disabled: this.isFooterButtonDisabled,
        loading: this.isStoringLoadingLink && this.isAgreeBuilderDataLoaded
      };
    },

    saveButton() {
      return {
        style: { width: '100px' },
        styleType: 'primary',
        iconRight: 'fa-regular fa-angle-right',
        text: 'Save',
        disabled: this.isFooterButtonDisabled,
        loading: this.isStoringLoadingLink && this.isAgreeBuilderDataLoaded
      };
    },

    editDocumentButton() {
      const styles = this.isCompletedApproval ? { opacity: 0.5, cursor: 'default' } : {};
      return {
        ...styles,
        styleType: 'secondary',
        iconLeft: 'fa-file-pen mr-2',
        text: 'Edit document',
        title: 'Document is approved. No additional edits can be made.'
      };
    },

    resubmitForApprovalButton() {
      return {
        styleType: 'primary',
        iconRight: 'fa-regular fa-thumbs-up',
        text: 'Resubmit for approval',
        loading: this.isStoringLoadingLink && this.isAgreeBuilderDataLoaded
      };
    },

    ...mapState(useHarbourStore, ['showEditDocument'])
  },

  methods: {
    createLink() {
      this.$emit('create-link');
    },

    saveLink() {
      this.$emit('save-link');
    },

    goPrevStep() {
      this.$emit('go-prev-step');
    },

    updateLinkTitle(linkTitle) {
      this.linkTitle = linkTitle;
    },

    updateInputChanges(hasMadeChanges) {
      this.hasMadeInputChanges = hasMadeChanges;
    },

    updateIsStoringLoadingLink(isStoring) {
      this.isStoringLoadingLink = isStoring;
    },

    updateIsLinkAvailable(isLinkAvailable) {
      this.isLinkAvailable = isLinkAvailable;
    },

    updateIsPendingValueChanges(isPendingValueChanges) {
      this.isPendingValueChanges = isPendingValueChanges;
    },

    updateIsAgreeBuilderDataLoaded(isLoaded) {
      this.isAgreeBuilderDataLoaded = isLoaded;
    },

    closeModal() {
      this.$parent.close();
    },

    removeModalOpenedClass() {
      document.documentElement.classList.remove('hrbr-agreement-link-modal--opened');
    },

    goToEditDocument() {
      // Open in Agreement Editor
      this.$emit('edit-document');
    },

    resubmitForApproval() {
      this.$emit('submit-approval')
    },

    handleApprovalDataLoaded(data) {
      this.approvalData = data;
    },
  },

  beforeDestroy() {
    this.removeModalOpenedClass();
  },
};
</script>

<template>
  <div
    class="hrbr-agreement-link-modal__card modal-card"
    :class="{ 'hrbr-agreement-link-modal__card--editor-flow': isAgreementEditorFlow }"
    id="hrbr-agreement-link-modal">
    <div class="hrbr-agreement-link-modal__header modal-card-head">
      <div class="hrbr-agreement-link-modal__link-name" v-if="!isAgreementEditorFlow">
        <div class="hrbr-agreement-link-modal__link-name-text" v-if="linkDisplayIdToEdit">
          <span>{{ linkTitle ? textDeSanitize(linkTitle) : '' }}</span>
        </div>
        <div class="hrbr-agreement-link-modal__link-name-text" v-else>New Link</div>
      </div>
      <b-steps
        v-else
        class="hrbr-agreement-link-modal__steps"
        v-model="currentStep"
        :has-navigation="false"
        type="is-success"
        size="is-medium"
        label-position="right"
        mobile-mode="minimalist"
        ref="stepper">
        <b-step-item
          v-for="(step, index) in stepOptions"
          :key="step.id"
          :step="step.step + 1"
          :label="step.label"
          :clickable="false"
          header-class="hrbr-agreement-link-modal__step-item">
        </b-step-item>
      </b-steps>

      <HrbrButton
        v-if="(showEditDocument(linkDisplayIdToEdit) || isEditingApprovalFlowLink)"
        class="edit-document-btn"
        :button="editDocumentButton"
        @click="goToEditDocument"
      />
    </div>

    <div class="hrbr-agreement-link-modal__body modal-card-body">
      <HrbrAgreementLink
        v-bind="{
          agreementId,
          templateId,
          linkDisplayIdToEdit,
          folderId,
          agreementCreationMode,
          isAgreementSignOrderCheckboxHidden,
          isPendingValueChanges,
          ckeditorFileId,
          ckeditorHtmlData,
          ckeditorAnchors,
          lastbrand,
          sourceTemplateTitle,
          forceCkeditorHtml,
          isEditingApprovalFlowLink,
          isCompletedApproval,
          approvalEditData,
          draftId,
          isCompletedApproval,
          superdocId,
          superdocProp,
          linkObject,
          forceUseAgreementTitle,
        }"
        @link-title-change="updateLinkTitle"
        @is-storing-loading-link-change="updateIsStoringLoadingLink"
        @is-link-available-change="updateIsLinkAvailable"
        @is-pending-value-changes="updateIsPendingValueChanges"
        @is-agree-builder-data-loaded-change="updateIsAgreeBuilderDataLoaded"
        @approval-data-loaded="handleApprovalDataLoaded"
        @close-modal="closeModal"
        @update-input-changes="updateInputChanges"
        ref="agreementLinkComponent">
      </HrbrAgreementLink>
    </div>

    <div class="hrbr-agreement-link-modal__footer modal-card-foot">
      <div class="hrbr-agreement-link-modal__footer-buttons" v-if="!approvalData?.length && (!isCompletedApproval || !hasMadeInputChanges)">
        <HrbrButton v-if="isAgreementEditorFlow && !linkDisplayIdToEdit" :button="previousButton" @click="goPrevStep"/>
        <HrbrButton v-if="linkDisplayIdToEdit" :button="saveButton" @click="saveLink"/>
        <HrbrButton v-else id="btn-create-link" :button="createButton" @click="createLink"/>
      </div>

      <!-- For workflow approvals -->
      <div v-else-if="isCompletedApproval" >
        <HrbrButton :button="resubmitForApprovalButton" @click="saveLink"/>
      </div>
      <div v-else-if="isEditingApprovalFlowLink && !isCompletedApproval">
        <HrbrButton :button="resubmitForApprovalButton" @click="resubmitForApproval"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.hrbr-button-container.edit-document-btn {
  margin: 5px 40px 0 0;
  position: absolute;
  right: 20px;
}
</style>

<style>
/* TODO:
- move to scoped style
- remove selector by id
*/

html.hrbr-agreement-link-modal--opened {
  overflow: hidden !important;
}
.hrbr-agreement-link-modal .modal-close {
  z-index: 9999;
}
.no-close .modal-close {
  display: none !important;
}
.hrbr-agreement-link-modal .modal-close::before,
.hrbr-agreement-link-modal .modal-close::after {
  background: #333;
}

.hrbr-agreement-link-modal .modal-close::before {
  width: 60%;
}

.hrbr-agreement-link-modal .modal-close::after {
  height: 60%;
}

.hrbr-agreement-link-modal .modal-close:hover::before,
.hrbr-agreement-link-modal .modal-close:hover::after {
  background: #fff;
}

#hrbr-agreement-link-modal {
  --modal-header-height: 65px;
  --modal-footer-height: 60px;
}

#hrbr-agreement-link-modal.hrbr-agreement-link-modal__card {
  background: #f5f5f5;
}

#hrbr-agreement-link-modal.hrbr-agreement-link-modal__card--editor-flow
  .hrbr-agreement-link-modal__header {
  justify-content: center;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__header {
  background: #fff;
  padding: 12px 20px;
  height: var(--modal-header-height);
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__link-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 500px;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__link-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__link-name-text span {
  font-weight: 400;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__steps .step-items {
  column-gap: 50px;
  flex-wrap: nowrap;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__steps .step-content {
  display: none;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__step-item {
  flex-basis: auto;
  flex-grow: initial;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__step-item .step-link:not(.is-clickable) {
  cursor: default;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__step-item .step-title {
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__step-item .step-marker {
  font-weight: 600;
  transition: all 0.2s ease;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__step-item.is-previous .step-marker::before {
  display: inline-block;
  content: '\f00c';
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__step-item.is-previous .step-marker span {
  display: none;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__body {
  padding: 0;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 8px 20px;
  height: var(--modal-footer-height);
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-buttons {
  display: flex;
  align-items: center;
  gap: 30px;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-button {
  font-family: inherit;
  font-size: 16px;
  color: #363636;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  min-height: 42px;
  min-width: 120px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  background: none;
  border: none;
  outline: none;
  appearance: none;
  transition: all 0.2s ease;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-button i {
  animation-duration: 0.5s;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-button--prev {
  border: 1px solid #dbdbdb;
}
#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-button--save-active {
  background: var(--main-primary-color-activefocus);
  font-weight: 600;
}
#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-button--prev:hover {
  border-color: #b5b5b5;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-button--create,
#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-button--save {
  color: #fff;
  background: var(--main-primary-color);
  border: 1px solid transparent;
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-button--create:hover,
#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-button--save:hover {
  background: var(--main-primary-color-activefocus);
}

#hrbr-agreement-link-modal .hrbr-agreement-link-modal__footer-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
